<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Dodaj nowy urlop</p>
    </header>
    <section class="modal-card-body">
      <b-datepicker v-model="dates" :month-names="months" :day-names="days" :first-day-of-week="1" :nearby-selectable-month-days="true" inline range />
    </section>
    <footer class="modal-card-foot">
      <b-button @click="$parent.close()" label="Anuluj" />
      <b-button type="is-primary" icon-left="calendar-plus" @click="add" label="Dodaj" />
    </footer>
  </div>
</template>

<script>
  import moment from "moment";

  export default {
    name: "AddVacation",
    data() {
      return {
        dates: [],
        months: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
        days: ["Nie", "Pon", "Wto", "Śro", "Czw", "Pią", "Sob"]
      }
    },
    methods: {
      add() {
        let dates = []
        dates[0] = moment(this.dates[0]).utc(true).format('YYYY-MM-DD HH:mm:ss')
        dates[1] = moment(this.dates[1]).utc(true).hours(23).minutes(59).seconds(59).format('YYYY-MM-DD HH:mm:ss')
        this.$emit("save", dates)
        this.$parent.close()
      }
    }
  }
</script>

<style scoped>
  @media screen and (min-width: 769px) {
    .modal-card {
      width: 100%;
    }
  }
</style>
