<template>
  <div class="content">
    <div v-if="!$store.getters['elogin/oAuthExpired'] || $store.getters['api/apiToken']('web_socket')" class="level is-mobile buttons">
      <div class="level-left">
        <b-button v-if="notificationsSupported && !notificationsEnabled" type="is-info" class="level-item" inverted icon-left="bell" @click="askPermission" />
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-button type="is-info" inverted icon-left="calendar" @click="$router.push({name: 'vacation'})">Zarządzaj urlopem</b-button>
        </div>
        <div class="level-item">
          <b-button type="is-info" inverted icon-left="logout-variant" @click="$logout()">Wyloguj</b-button>
        </div>
      </div>
    </div>
    <template v-if="connection">
      <h1 class="has-text-centered">Alarmy</h1>
      <b-table :data="problems" class="allerts-table">
        <b-table-column v-slot="props" field="host" label="System/Urządzenie" cell-class="blink">
          {{ props.row.host }}
        </b-table-column>
        <b-table-column v-slot="props" field="problem" label="Problem" cell-class="blink">
          {{ props.row.problem }}
        </b-table-column>
        <b-table-column v-slot="props" field="person1" label="Osoba do kontaktu w pierwszej kolejności" cell-class="blink">
          <template v-if="props.row.person1_name">
            {{ props.row.person1_name }}<br />
            telefon: {{ props.row.person1_phone }}
          </template>
        </b-table-column>
        <b-table-column v-slot="props" field="person2" label="Osoba do kontaktu w drugiej kolejności" cell-class="blink">
          <template v-if="props.row.person2_name">
            {{ props.row.person2_name }}<br />
            telefon: {{ props.row.person2_phone }}
          </template>
        </b-table-column>
        <b-table-column v-slot="props" field="duration" label="Czas trwania awarii" cell-class="blink">
          {{ props.row.duration }} minut
        </b-table-column>
        <template #empty>
          <div class="has-text-centered">Brak alarmów</div>
        </template>
      </b-table>
    </template>
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    name: "Home",
    data() {
      return {
        connection: null,
        polling: null,
        problems: [],
        notificationsSupported: false,
        notificationsEnabled: false,
        alarm: new Audio("./alarm.mp3")
      }
    },
    methods: {
      createWebSocket(token) {
        try {
          this.connection = new WebSocket(process.env.VUE_APP_WS + "?token=" + token)

          this.connection.onmessage = event => {
            this.problems = JSON.parse(event.data)
          }

          this.connection.onopen = () => {
            console.log("Poprawnie połączono do WebSocket...")
          }

          this.connection.onerror = () => {
            this.problems = []
            this.connection.close()
          }

          this.connection.onclose = () => {
            this.connection = null
            setTimeout(this.createWebSocket(token), 5000)
          }
        } catch (error) {
          this.problems = []
          this.$route.query.token = undefined
          this.connection.close()
        }
      },
      askPermission() {
        if (this.notificationsSupported) {
          Notification.requestPermission(result => {
            if (result === "granted") {
              this.notificationsEnabled = true
            }
          })
        }
      },
      showNotification(problem) {
        if (this.notificationsSupported) {
          navigator.serviceWorker.ready.then(swreg =>
            swreg.showNotification("Nowy alarm!", {
              body: problem,
              icon: "/img/icons/android-chrome-512x512.png",
              vibrate: [300, 200, 300],
              badge: "/img/icons/apple-touch-icon-76x76.png"
            })
          )
        }
      }
    },
    watch: {
      problems: function (newValue, oldValue) {
        const newProblems = newValue.map(problem => problem.problem)
        const oldProblems = oldValue.map(problem => problem.problem)
        let difference = newProblems.filter(x => !oldProblems.includes(x))
        if (newValue.length > oldValue.length) {
          difference.forEach(diff => this.showNotification(diff))
          let audioLoops = 0
          this.alarm.addEventListener('ended', (event) => {
            if (audioLoops < 2) {
              event.target.currentTime = 0
              event.target.play()
            } else {
              audioLoops = 0
              event.target.pause()
            }
            audioLoops++
          })
          this.alarm.play()
        }
      }
    },
    async mounted() {
      if (this.$route.query.token !== undefined)
        this.createWebSocket(this.$route.query.token)
      else if (this.$store.getters["api/apiToken"]("web_socket"))
        this.createWebSocket(this.$store.getters["api/apiToken"]("web_socket"))
      else if (!this.$store.getters["elogin/oAuthExpired"]) {
        axios
            .post(process.env.VUE_APP_REST + "/token", {
              scope: "web_socket",
              access_token: this.$store.getters["elogin/accessToken"]
            })
            .then(response => {
              this.$store.commit("api/saveToken", {scope: "web_socket", api_token: response.data.api_token})
              this.createWebSocket(this.$store.getters["api/apiToken"]("web_socket"))
              if ("Notification" in window && "serviceWorker" in navigator) this.notificationsSupported = true
              if (Notification.permission === "granted") this.notificationsEnabled = true
            })
            .catch(error => {
              console.error(error)
              this.$redirectToELogin()
            })
      } else {
        this.$redirectToELogin()
      }
    }
  }
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .b-table >>> .table-wrapper.has-mobile-cards tr {
    box-shadow: none;
  }
  .b-table >>> .table-wrapper.has-mobile-cards tr:not([class*=is-]) {
    background-color: initial;
  }
}
</style>
