<template><div></div></template>

<script>
  export default {
    name: "Login",
    mounted() {
      this.$store.commit("elogin/saveToken", {
        access_token: this.$route.query.access_token,
        token_type: this.$route.query.token_type,
        expires_in: Number(this.$route.query.expires_in),
        timestamp: Math.round(new Date().getTime() / 1000)
      })
      if (this.$route.query.state)
        window.location.href = this.$route.query.state
      else
        this.$router.push({name: "home"})
    }
  }
</script>
