import Home from "@/pages/Home"
import Login from "@/pages/Login"
import Vacation from "@/pages/Vacation"

export default [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/vacation",
    name: "vacation",
    component: Vacation
  }
]
