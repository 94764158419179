import Vue from "vue"
import App from "./App.vue"
import VueRouter from "vue-router"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"
import Buefy from "buefy"
import moment from "moment"
import routes from "./routes"
import "./registerServiceWorker"

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(Buefy)

Vue.config.productionTip = false
Vue.prototype.moment = moment
Vue.prototype.$eLoginClientId = "monitoring.esys.put.poznan.pl"

Vue.prototype.$redirectToELogin = function () {
  const scope = "ekonto.user"
  const state = encodeURIComponent(window.location.href)
  window.location.href = "https://elogin.put.poznan.pl/?do=OAuth&response_type=token&client_id=" + this.$eLoginClientId + "&scope=" + scope + "&state=" + state
}

Vue.prototype.$logout = function () {
  this.$store.commit("api/saveToken", {scope: "web_socket", api_token: null})
  this.$store.commit("api/saveToken", {scope: "vacation", api_token: null})
  this.$store.commit("elogin/saveToken", {
    access_token: null,
    token_type: null,
    expires_in: 0,
    timestamp: 0
  })
  window.location.href = "https://elogin.put.poznan.pl/?do=Logout&system=" + this.$eLoginClientId
}

const router = new VueRouter({
  routes
})

const api = {
  namespaced: true,
  state: {
    api_token: {
      web_socket: null,
      vacation: null
    }
  },
  getters: {
    apiToken: state => scope => state.api_token[scope]
  },
  mutations: {
    saveToken(state, payload) {
      state.api_token[payload.scope] = payload.api_token
    }
  }
}

const elogin = {
  namespaced: true,
  state: {
    access_token: null,
    token_type: null,
    expires_in: 0,
    timestamp: 0
  },
  getters: {
    oAuthExpired: state => Math.round(new Date().getTime() / 1000) - state.expires_in > state.timestamp,
    accessToken: state => state.access_token
  },
  mutations: {
    saveToken(state, payload) {
      state.access_token = payload.access_token
      state.token_type = payload.token_type
      state.expires_in = payload.expires_in
      state.timestamp = payload.timestamp
    }
  }
}

const vuexLocalStorage = new VuexPersistence({
  key: "api",
  storage: window.localStorage,
  modules: ["api"]
})

const vuexSessionStorage = new VuexPersistence({
  key: "elogin",
  storage: window.sessionStorage,
  modules: ["elogin"]
})

const store = new Vuex.Store({
  state: {},
  getters: {},
  modules: {
    api: api,
    elogin: elogin
  },
  plugins: [vuexLocalStorage.plugin, vuexSessionStorage.plugin]
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app")
