<template>
  <div class="content">
    <div class="level-right buttons">
        <b-button type="is-primary" class="level-item" icon-left="calendar-plus" @click="add">Dodaj</b-button>
        <b-button type="is-info" class="level-item" inverted icon-left="monitor-dashboard" tag="router-link" to="/">Pulpit</b-button>
        <b-button type="is-info" class="level-item" inverted icon-left="logout-variant" @click="$logout()">Wyloguj</b-button>
    </div>
    <h1 class="has-text-centered">Urlopy</h1>
    <div class="container is-max-desktop">
      <b-table :data="vacations" :loading="isLoading" narrowed>
        <b-table-column v-slot="props" field="vacation_from" label="Od">
          {{ moment(props.row.vacation_from).format("DD.MM.YYYY") }}
        </b-table-column>
        <b-table-column v-slot="props" field="vacation_to" label="Do">
          {{ moment(props.row.vacation_to).format("DD.MM.YYYY") }}
        </b-table-column>
        <b-table-column v-slot="props" label="Akcje">
          <div class="field buttons actions p-2">
            <b-tooltip label="Usuń" position="is-left">
              <b-button type="is-primary" icon-left="calendar-remove" @click="remove(props.row.id)" />
            </b-tooltip>
          </div>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered">Brak urlopów</div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import AddVacation from "@/components/modals/AddVacation"

  export default {
    name: "Vacation",
    data() {
      return {
        isLoading: false,
        vacations: []
      }
    },
    methods: {
      add() {
        this.$buefy.modal.open({
          parent: this,
          component: AddVacation,
          hasModalCard: true,
          ariaModal: true,
          canCancel: false,
          customClass: "add-vacation",
          events: {
            save: value => {
              axios
                .post(process.env.VUE_APP_REST + "/vacation", value, {headers: {Authorization: `Bearer ${this.$store.getters["api/apiToken"]("vacation")}`}})
                .then(response => {
                  if (response.status === 201) {
                    this.loadAsyncData()
                  }
                })
                .catch(error => {
                  console.error(error)
                  this.$store.commit("api/saveToken", {scope: "vacation", api_token: null})
                  this.$redirectToELogin()
                })
            }
          }
        })
      },
      remove(id) {
        axios
          .delete(process.env.VUE_APP_REST + "/vacation/" + id, {headers: {Authorization: `Bearer ${this.$store.getters["api/apiToken"]("vacation")}`}})
          .then(response => {
            if (response.status === 204) {
              this.loadAsyncData()
            }
          })
          .catch(error => {
            console.error(error)
            this.$store.commit("api/saveToken", {scope: "vacation", api_token: null})
            this.$redirectToELogin()
          })
      }
    },
    async mounted() {
      this.isLoading = true
      if (!this.$store.getters["elogin/oAuthExpired"] && !this.$store.getters["api/apiToken"]("vacation")) {
        await axios
            .post(process.env.VUE_APP_REST + "/token", {
              scope: "vacation",
              access_token: this.$store.getters["elogin/accessToken"]
            })
            .then(response => {
              this.$store.commit("api/saveToken", {scope: "vacation", api_token: response.data.api_token})
            })
            .catch(error => {
              console.error(error)
              this.$store.commit("api/saveToken", {scope: "vacation", api_token: null})
              this.$redirectToELogin()
            })
      }
      await axios
          .get(process.env.VUE_APP_REST + "/vacation", {headers: {Authorization: `Bearer ${this.$store.getters["api/apiToken"]("vacation")}`}})
          .then(response => {
            this.vacations = response.data
          })
          .catch(error => {
            console.error(error)
            this.$store.commit("api/saveToken", {scope: "vacation", api_token: null})
            this.$redirectToELogin()
          })
          .finally(() => {
            this.isLoading = false
          })
    }
  }
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .b-table >>> .table-wrapper.has-mobile-cards tr, .b-table >>> .table-wrapper.has-mobile-cards tr:hover {
    box-shadow: none;
    background: inherit;
    overflow: hidden;
  }
}
</style>
