<template>
  <router-view />
</template>

<script>
  export default {
    name: "App"
  }
</script>

<style lang="scss">
  @import "https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css";
  @import "https://fonts.googleapis.com/css?family=Exo+2&display=swap";
  @import "variables";
  @import "~bulma";
  @import "~buefy/src/scss/buefy";

  html,
  body {
    background-color: $light;
    color: white;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .content {
    margin: 20px;
  }

  .content h1 {
    color: white;
  }

  .content table,
  .content table thead th {
    background-color: $dark;
    color: white;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  .blink {
    transition: all 0.5s ease-in-out;
    animation: blink normal 1.5s infinite ease-in-out;
  }
</style>
